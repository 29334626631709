import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/LitreryClub/2024/Tricolour/1.jpg';
// import p2 from 'assests/Photos/Clubs/LitreryClub/2024/Tricolour/2.jpg';
// import p3 from 'assests/Photos/Clubs/LitreryClub/2024/Tricolour/3.jpg';
// import p4 from 'assests/Photos/Clubs/LitreryClub/2024/Tricolour/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const TricolourTribute2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Tricolour/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Tricolour/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Tricolour/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Tricolour/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        // {
        //     src: p5,
        //     source: p5,
        //     rows: 1,
        //     cols: 1,
        // }, 
        // {
        //     src: p6,
        //     source: p6,
        //     rows: 1.4,
        //     cols: 2,
        // }
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Tricolour Tribute
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 to 12  &  Date: 13 August 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Literary Association of National Public School, Yeshwanthpur organised an inter–house competition titled “Tricolour Tribute” for students of classes 9 to 12 on 13 August 2024. The event celebrated India’s rich cultural heritage and fostered a sense of national pride among students.
                                <br></br>
                                Students participated with vigour and enthusiasm. They showcased their talents by creating intricate rangolis with an array of colours and flowers. The rangolis beautifully depicted India’s national symbols like majestic peacock, the serene lotus and iconic monuments such as the India Gate and Qutub Minar. The students also wrote thought provoking slogans, in Hindi, Sanskrit and English, which emphasised the importance of unity, peace and patriotism.
                                <br></br>
                                The event was indeed a visual delight that paid a rich tribute to India’s glorious past, robust present and exhilarating future. The event successfully achieved its objectives of promoting traditional art, creativity and team work. The vibrant tricolour rangolis filled everybody’s heart with patriotic 
                                <br></br>
                                
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "One can speak poetry just by arranging colours well-Vincent Van Gogh"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        
                        <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box>
                        {/* <Box marginBottom={4}>
                                    <Archive2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                                    <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default TricolourTribute2024;